.shelfeListItem {
  color: black;
  border-radius: 32px!important;
  background-color: #69D0D2!important;
  margin: 0 16px 16px 16px!important;
  width: 90%!important;
  min-height: 64px;
}

.shelfeListItem.selected {
  width: 75%!important;
}

.shelfeListItem > div,
.shelfeListItem > div > span {
  color: black;
}
