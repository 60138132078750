* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: rgba(0, 0, 0, .87);
  background-color: #FAFAFA;
}

body, input, button, textarea, p, a, span, i, b, strong {
  font-family: 'Nunito', sans-serif!important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Righteous', cursive!important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

