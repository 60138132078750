#shelfe-logo {
  max-width: 240px;
}

#left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#sign-in-button {
  margin-top: 32px;
}

#google-sign-in-button {
  margin-top: 32px;
  margin-left: 16px;
}